<template>
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content">
				<div class="centered">
					<div class="centered__wrap">
						<div class="textbox">
							<h3 class="textbox__title">
								비밀번호 변경 완료
							</h3>
							<p class="textbox__description">
								비밀번호 변경이 완료되었습니다. <br>
								로그인 페이지로 이동합니다.
							</p>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<router-link to="/layers/login" custom v-slot="{ navigate }">
				<button type="button" class="btn btn-confirm" @click="navigate">로그인 하기</button>
			</router-link>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>